import PassageEditor from './PassageEditor';
import React, {useState} from 'react';
import {PassageMenu} from "./PassageMenu";
import SettingsPage from "./SettingsPage";
import useStorySnapshot from './hooks/useStorySnapshot';
import usePassagesSnapshot from './hooks/usePassagesSnapshot';

function StoryEditor(props) {
    const [currentPassageId, setCurrentPassageId] = useState(1);
    const [onSettings, setOnSettings] = useState(false);
    const {story} = useStorySnapshot(props.currentStory);
    const {passages} = usePassagesSnapshot(props.currentStory);
    const passageIndexes = passages ? passages.map(passage => passage.id) : [];
    const isDirectionRTL = story ? story.direction === "rtl" : true;
    const title = story ? story.title : "";

  return (
    <>
        <h1>{title}</h1>
        <div dir={isDirectionRTL ? "rtl" : "ltr"} style={{ display: "flex", width: "96%" }}>
            <div style={{ flex: 1 }}>
                <PassageMenu passageIndexes={passageIndexes}
                             currentPassageId={currentPassageId}
                             setCurrentPassageId={setCurrentPassageId}
                             storyId={props.currentStory}
                             setOnSettings={setOnSettings}
                             onSettings={onSettings}
                             isDirectionRTL={isDirectionRTL}
                             passages={passages}
                />
            </div>
            {onSettings ? (
                <>
                    <div style={{ flex: 8, display: "flex" }}>
                        <SettingsPage currentStory={props.currentStory} story={story} />
                    </div>
                    <div style={{ flex: 1, display: "flex" }}>
                    </div>
                </>
            ) : (
                <div style={{ flex: 9, display: "flex" }} dir={isDirectionRTL ? "rtl" : "ltr"}>
                    <PassageEditor currentStory={props.currentStory}
                                   currentPassageId={currentPassageId}
                                   setCurrentPassageId={setCurrentPassageId}
                                   passageIndexes={passageIndexes}
                                   isDirectionRTL={isDirectionRTL}
                    />
                </div>
            )}
        </div>
    </>
);
}

export default StoryEditor;
