import React from 'react';
// import useState from 'react';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { Input, Button } from '@mui/material';
import PlayerMenu from './PlayerMenu';
import { useSelector } from 'react-redux';
import StoryViewer from './StoryViewer';


const PlayerPage = () => {
    const currentStory = useSelector(state => state.player.currentStory);

    return (
        <div>
            {currentStory ? <StoryViewer /> : <PlayerMenu />}
        </div>
    );
};

export default PlayerPage;
