import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { changeMode, MODES } from './modeSlice';
import { Link } from 'react-router-dom';

function MainMenu() {
    const dispatch = useDispatch();

    const handlePlayerClick = () => {
        dispatch(changeMode(MODES.PLAYER));
    };

    const handleAuthorClick = () => {
        dispatch(changeMode(MODES.AUTHOR));
    };

    return (
        <div style={{margintTop: "10px"}}>
            <div>
                <Link to="/textAdventures/story">
                    <Button variant="contained" color="primary" onClick={handlePlayerClick} 
                        style={{width: "100%", marginBottom: "4px"}}>
                        Play a game
                    </Button>
                </Link>
            </div>
            <div>
                <Link to="/textAdventures/edit">
                    <Button variant="contained" color="secondary" onClick={handleAuthorClick}>
                        Create or edit a game
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default MainMenu;
