import useSnapshot from './useSnapshot';

const usePassagesSnapshot = (storyId) => {
  const { data, loading, error } = useSnapshot(`stories/${storyId}/draftPassages`);

  return {
    passages: data,
    loading,
    error,
  };
};

export default usePassagesSnapshot;