import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';

const RequestStatusIndicator = ({
    isSuccess,
    isLoading,
    error,
    successTimeout = 3000,
    failureTimeout = 3000
}) => {
    // State to manage the display status of the indicator
    const [displayStatus, setDisplayStatus] = useState('idle'); // 'idle', 'success', 'failure'

    useEffect(() => {
        if (!isLoading && isSuccess && !error) {
            setDisplayStatus('success');
            const timer = setTimeout(() => setDisplayStatus('idle'), successTimeout);
            return () => clearTimeout(timer);
        }

        // If loading is done and there is an error, show failure.
        if (!isLoading && error) {
            setDisplayStatus('failure');
            const timer = setTimeout(() => setDisplayStatus('idle'), failureTimeout);
            return () => clearTimeout(timer);
        }

        // Reset to 'idle' when loading starts again.
        if (isLoading) {
            setDisplayStatus('loading');
        }

    }, [isLoading, isSuccess, error, successTimeout, failureTimeout]);

    // Determine what to render based on the displayStatus.
    let content = null;
    switch (displayStatus) {
        case 'loading':
            content = <CircularProgress />;
            break;
        case 'success':
            content = <CheckCircleOutlineIcon color="success" />;
            break;
        case 'failure':
            content = <HighlightOffIcon color="error" />;
            break;
        default:
            content = null; // 'idle' or any other state will render nothing.
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {content}
        </Box>
    );
};

export default RequestStatusIndicator;