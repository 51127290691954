import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Badge from '@mui/material/Badge';

function ChoiceEditor(props) {
    const nextOptions = props.passageIndexes
      .sort((a, b) => a - b)
      .map(index => <MenuItem value={index}>{index}</MenuItem>);
    const isError = props.passageIndexes.includes(props.next.toString());

    return (
      <>
          <div style={{display: 'flex', marginBottom: "10px", alignItems: "center", 
            justifyContent: "center"}}>
              <Fab color="primary" size="small" style={{width: "6%"}} onClick={() => {
                  props.removeChoice(props.index, props.dispatch)
              }}>
                  <RemoveRoundedIcon/>
              </Fab>
              <TextField
                id="outlined-multiline-flexible"
                multiline
                maxRows={4}
                onChange={(event) => props.editChoice(event.target.value, props.next, props.index, props.dispatch)}
                value={props.text}
                style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginInlineStart: '2%',
                    backgroundColor: "white",
                    width: "100%"
                }}
              />
            <Badge color="error" badgeContent="!" invisible={isError}
                   anchorOrigin={{
                     vertical: "top",
                     horizontal: props.isDirectionRTL ? "left" : "right"
                   }}>
              <FormControl fullWidth sx={{width: "auto"}}>
                  {/*<InputLabel id="demo-simple-select-label">leads to</InputLabel>*/}
                  <Select
                    sx={{backgroundColor: "white", marginInlineStart: "10px", width: "auto-fit"}}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.next}
                    onChange={(event) => props.editChoice(props.text, event.target.value, props.index, props.dispatch)}
                  >
                      {nextOptions}
                  </Select>
              </FormControl>
            </Badge>
          </div>
      </>
    );
}

export default ChoiceEditor;
