import { ToggleButtonGroup, Stack, ToggleButton } from '@mui/material';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

export function SiteDirectionSetting(props) {
  return <Stack direction="row" spacing={4}>
    <ToggleButtonGroup
      value={props.value}
      exclusive
      onChange={props.onChange}
      sx={{backgroundColor: "white"}}
    >
      <ToggleButton value={false}>
        <FormatAlignLeftIcon/>
      </ToggleButton>
      <ToggleButton value={true}>
        <FormatAlignRightIcon/>
      </ToggleButton>
    </ToggleButtonGroup>
  </Stack>;
}
