import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import useStack from './useStack';
import Button from '@mui/material/Button';
import ChoiceViewer from './ChoiceViewer';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const PUBLISHED_PASSAGES_COLLECTION = 'passages';
const DRAFT_PASSAGES_COLLECTION = 'draftPassages';

const StoryViewer = ({isDraft = false}) => {
  const { lookupKey } = useParams();
  const [currentStoryId, setCurrentStoryId] = useState(null);
  const [storyNotFound, setStoryNotFound] = useState(false);
  const [currentPassageId, setCurrentPassageId] = useState(1);
  const [currentPassage, setCurrentPassage] = useState(null);
  const [isDirectionRTL, setIsDirectionRTL] = useState(false);
  const { push, pop, isEmpty } = useStack();
  const db = getFirestore();

  // useEffect for fetching the story ID based on lookupKey
  useEffect(() => {
    const fetchStoryId = async () => {
      try {
        const storiesRef = collection(db, 'stories');
        const q = query(storiesRef, where('lookupKey', '==', lookupKey));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const storyDoc = querySnapshot.docs[0];
          setCurrentStoryId(storyDoc.id);
          setIsDirectionRTL(storyDoc.data().direction === 'rtl');
        } else {
          console.log('Story document does not exist.');
          setCurrentStoryId(null);
          setStoryNotFound(true);
        }
      } catch (error) {
        console.error('Error fetching story:', error);
      }
    };

    if (lookupKey) {
      fetchStoryId();
    }
  }, [lookupKey, db]);

  // useEffect for fetching the passage whenever currentStoryId or currentPassageId changes
  useEffect(() => {
    const fetchPassage = async () => {
      if (currentStoryId && currentPassageId != null) {
        try {
          const passageRef = doc(db, 'stories', currentStoryId, isDraft ? DRAFT_PASSAGES_COLLECTION : PUBLISHED_PASSAGES_COLLECTION, currentPassageId.toString());
          const passageSnapshot = await getDoc(passageRef);

          if (passageSnapshot.exists()) {
            setCurrentPassage(passageSnapshot.data());
          } else {
            console.log('Passage document does not exist.');
            setCurrentPassage(null);
          }
        } catch (error) {
          console.error('Error fetching passage:', error);
        }
      }
    };

    fetchPassage();
  }, [currentStoryId, currentPassageId, db, isDraft]);

  return (
    <div style={{display: "flex", width: "100%", justifyContent: "center", 
    direction: isDirectionRTL ? "rtl" : "ltr"}}>
      {currentPassage ? (
        <div style={{width: "45%", barginTop: "13px"}}>
        
        <Button
            variant="outlined"
            startIcon={<ArrowBackIosIcon sx={{ transform: isDirectionRTL ? 'scaleX(-1)' : 'none' }} />}
            onClick={() => {setCurrentPassageId(pop());}}
            style={{
              visibility: isEmpty ? 'hidden' : 'visible',
              pointerEvents: isEmpty ? 'none' : 'auto'
            }}
            >
        </Button>
          <p style={{whiteSpace: "pre-wrap", textAlign: "start", 
            border: "1px solid", padding: "10px", fontSize: "large"}}>
            {currentPassage.text}
          </p>
          {currentPassage.choices.map((choice, index) => (
            <ChoiceViewer 
            text={choice.text} 
            next={choice.next} 
            onClick={() => {push(currentPassageId); setCurrentPassageId(choice.next)}} 
            isDirectionRTL={isDirectionRTL}
            />
            ))
          }
        </div>
      ) : (
        <></>
      )}
      {storyNotFound && <p>Story not found.</p>}
    </div>
  );
};

export default StoryViewer;