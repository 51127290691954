import React, { useState } from 'react';
import StoryList from './StoryList';
import StoryEditor from './StoryEditor';
import { useAuth } from './AuthContext';

function AuthorPage() {
    const [currentStory, setCurrentStory] = useState(null);
    const { currentUser } = useAuth();

    return (
        <>
            {currentUser && !currentStory && <StoryList setCurrentStory={setCurrentStory} />}
            {currentStory && <StoryEditor currentStory={currentStory} />}
            {!currentUser && <span>Sign in to create or edit a story</span>}
        </>
    );
}

export default AuthorPage;
