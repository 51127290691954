import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Input, Button } from '@mui/material';

const PlayerMenu = () => {
    const [magicPhrase, setMagicPhrase] = useState('cool-example');
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setMagicPhrase(event.target.value);
    };

    const handleSubmit = () => {
        navigate(`/textAdventures/story/${magicPhrase}`);
    };

    return (
        <div>
            <p>Enter magic phrase to start a game</p>
            <div style={{display: "flex", justifyContent: "space-between"}}>
            <Input
                value={magicPhrase}
                onChange={handleInputChange}
                style={{ backgroundColor: 'white' }}
            />
            <Button
                variant="contained"
                color="primary"
                disabled={!magicPhrase}
                onClick={handleSubmit}
                endIcon={<ArrowForwardIosIcon />}
            >
            </Button>
            </div>
        </div>
    );
};

export default PlayerMenu;
