import { useState, useCallback } from 'react';

function useApi(apiFunc) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);  // Added isSuccess state

  const call = useCallback(async (...args) => {
    setIsLoading(true);
    setError(null);
    setIsSuccess(false);  // Reset isSuccess to false when a new call starts
    try {
      const response = await apiFunc(...args);
      setData(response);
      setIsSuccess(true);  // Set isSuccess to true when the call succeeds
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setIsSuccess(false);  // Set isSuccess to false when the call fails
      setIsLoading(false);
    }
  }, [apiFunc]);

  return { data, isLoading, error, isSuccess, call }; // Include isSuccess in the returned object
}

export default useApi;