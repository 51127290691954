import useSnapshot from './useSnapshot';

const useStorySnapshot = (storyId) => {
    const { data, loading, error } = useSnapshot(`stories/${storyId}`);

    return {
      story: data,
      loading,
      error,
    };
};

export default useStorySnapshot;