import { signInWithPopup, signOut } from "firebase/auth";
import { auth , googleProvider} from "./config/firebase";
import { useAuth } from "./AuthContext";
import { Link } from 'react-router-dom';

export const Auth = () => {
  const { currentUser } = useAuth();

  const signInWithGoogle = async () => {
    try {
      googleProvider.setCustomParameters({ prompt: 'select_account' });
      await signInWithPopup(auth, googleProvider);
    } catch (err){
      console.error(err);
    }
  };
  const logOut = async () => {
    try {
    await signOut(auth);
    } catch (err){
      console.error(err);
    }
  };
  return (
      <div>
            <>
              <p style={{color: "white", marginBottom: "5px", marginTop: "5px", padding: "5px", display: "flex"}}>
                Welcome, {currentUser ? currentUser.displayName : "guest"}
              </p>
              {currentUser && <button onClick={logOut} style={{marginRight: "5px"}}>Log out</button>}
              {!currentUser && <button onClick={signInWithGoogle} style={{marginRight: "5px"}}>Sign in with Google</button>}
              <Link to="/textAdventures/story"><button style={{marginRight: "5px"}}>play a game</button></Link>
              <Link to="/textAdventures/edit"><button >create a game</button></Link>
            </>
      </div>
  );
};
