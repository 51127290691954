// import { firebase } from 'firebase/app';

const BASE_URL= "/api";

export async function makeAuthenticatedRequestToEndpoint(endpoint, method, body, auth) {
    console.log(endpoint);
    console.log(BASE_URL);
    return makeAuthenticatedRequest(BASE_URL + "/" + endpoint, method, body, auth)
}
async function makeAuthenticatedRequest(url, method = 'GET', body, auth) {
    const user = auth?.currentUser;
    if (!user) throw new Error("No user is signed in");

    const idToken = await user.getIdToken();

    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify(body),
    });

    return response.json();
}
