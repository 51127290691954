import modeReducer from '../modeSlice';
import PlayerSlice from '../PlayerSlice';

import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
    reducer: {
        mode: modeReducer,
        player: PlayerSlice,
    },
});