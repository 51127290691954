import React from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ChoiceViewer = (props) => {
  const { text, next, onClick } = props;

  const handleClick = () => {
    onClick(next);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', 
      backgroundColor: "#E08B0B", padding: "3px", border: "1px solid", marginBottom: "6px"}}>
      <span style={{fontSize: "large"}}>{text}</span>
      <Button variant="contained" style={{backgroundColor: "#9E2A2B"}} 
        endIcon={<ArrowForwardIosIcon 
          sx={{transform: props.isDirectionRTL ? 'scaleX(-1)' : 'none' }}/>} 
          onClick={handleClick}>
      </Button>
    </div>
  );
};

export default ChoiceViewer;