import { useState, useEffect } from 'react';
import { getFirestore, collection, doc, onSnapshot } from 'firebase/firestore';

const useSnapshot = (path) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!path) {
      setError('No path provided');
      setLoading(false);
      return;
    }

    const db = getFirestore();
    
    // Creating a reference
    const isCollection = path.split('/').length % 2 === 1;
    const ref = isCollection ? collection(db, path) : doc(db, path);
    
    const unsubscribe = onSnapshot(
      ref,
      snapshot => {
        setLoading(false);
        if (isCollection) {
          // Handle collection data
          let collectionData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setData(collectionData);
        } else {
          // Handle document data
          setData(snapshot.exists() ? { id: snapshot.id, ...snapshot.data() } : null);
        }
      },
      err => {
        setError(err);
        setLoading(false);
      }
    );

    // Detach the listener when the component unmounts or path changes
    return () => unsubscribe();
  }, [path]); // Re-run the effect if the path changes

  return { data, loading, error };
};

export default useSnapshot;