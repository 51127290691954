import Fab from '@mui/material/Fab';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeAuthenticatedRequestToEndpoint } from './Api';
import {useAuth} from './AuthContext';
import Button from '@mui/material/Button';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

const StoryItem = (props) => {
  const auth = useAuth();
  const endpoint = "story/" + props.storyId;
  const showThisDelete = props.openDelete === props.storyId;
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Button onClick={() => props.setOpenDelete("")} 
              style={{ visibility: showThisDelete ? 'visible' : 'hidden' }}>
                <CancelIcon color="warning" />
              </Button>
               <Button 
               style={{ visibility: showThisDelete ? 'visible' : 'hidden' }}
               onClick={() =>
                makeAuthenticatedRequestToEndpoint(endpoint, "DELETE", {}, auth)}>
                <DeleteIcon color="error" />
              </Button>
              <Button onClick={() => props.setOpenDelete(props.storyId)}
              style={{ visibility: !showThisDelete ? 'visible' : 'hidden' }}
              >
                <DeleteIcon color="error" />
              </Button>
                <Fab color="primary" aria-label="add" size="small"
                     onClick={() => props.setCurrentStory(props.storyId)}
                     style={{marginRight: "10px"}}>
                    <EditNoteRoundedIcon/>
                </Fab>
            </div>
            <div>
                {props.title}
            </div>
        </div>
    );
}

export default StoryItem;
