
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyAs2ZODbI5CC7pwsdOa5VL2n11p2BBLZWs",
    authDomain: "cyoa-386412.firebaseapp.com",
    databaseURL: process.env.DB_URL,
    projectId: "cyoa-386412",
    storageBucket: "cyoa-386412.appspot.com",
    messagingSenderId: "956985329873",
    appId: "1:956985329873:web:a130807dbe537b548e5df0",
    measurementId: "G-05C8J562WG"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth =  getAuth(app);
export const googleProvider = new GoogleAuthProvider();
