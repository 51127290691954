import {doc, getFirestore, updateDoc} from 'firebase/firestore';
import {SiteDirectionSetting} from "./SiteDirectionSetting";
import { Button } from "@mui/material";
import { makeAuthenticatedRequestToEndpoint } from './Api';
import {useAuth} from './AuthContext';
import RequestStatusIndicator from './RequestStatusIndicator';
import useApi from "./useApi";

function SettingsPage({story, currentStory}) {
  const db = getFirestore();
  const publishEndpoint = "story/" + currentStory + "/publish";
  const auth = useAuth();
  const { isSuccess, isLoading, error, call: callApi } = useApi(makeAuthenticatedRequestToEndpoint);  
  const lookupKey = story ? story.lookupKey : "";
  const isDirectionRTL = story ? story.direction === "rtl" : true;
  const siteUrl = "https://tamarka.in/textAdventures/";
  const storyUrl = siteUrl + "story/" + lookupKey;
  const draftUrl = siteUrl + "draft/" + lookupKey;

  return (
  <>
  <div dir="ltr" style={{width: "100%"}}>
    <div style={{display: "flex", justifyContent: "center"}}>
      <div style={{display: "flex", flexDirection: "column", width: "45%"}}>
      <div style={{display: "flex", justifyContent: "space-between"}}>
      Story alignment
      <SiteDirectionSetting value={isDirectionRTL}
                          onChange={(event, isRTL) => updateDoc(doc(db, "stories", currentStory), {direction: isRTL ? "rtl" : "ltr"})}/>
    </div>
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <div>magic phrase</div> 
      <div>{lookupKey}</div>
    </div>
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <div>draft preview</div> 
      <div>{draftUrl}</div>
    </div>
    {story.isPublished && <div style={{display: "flex", justifyContent: "space-between"}}>
      <div>story url</div> 
      <div>{storyUrl}</div>
    </div>}
    <div style={{display: "flex", justifyContent: "center"}}>
    <RequestStatusIndicator isSuccess={isSuccess} isLoading={isLoading} error={error} />
    <Button onClick={() => callApi(publishEndpoint, "POST", {}, auth)}
      variant="contained" style={{marginTop:"8px"}}>
      {story.isPublished ? "Publish changes" : "Publish"}
    </Button>
    </div>
    </div>
    </div>
  </div>
  </>
  );
}

export default SettingsPage;
