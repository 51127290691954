import React, {useState, useEffect} from 'react';
import {getFirestore, collection, query, where, onSnapshot} from 'firebase/firestore';
import {useAuth} from './AuthContext';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
// import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
// import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';

import { makeAuthenticatedRequestToEndpoint } from './Api';
import StoryItem from "./StoryItem";

const STORY_COLLECTION = "stories";

const StoryList = (props) => {
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showNewStory, setShowNewStory] = useState(false);
    const [newStoryTitle, setNewStoryTitle] = useState("");
    const [openDelete, setOpenDelete] = useState("");

    const auth = useAuth();

    useEffect(() => {
        const db = getFirestore();
        const currentUserId = auth.currentUser?.uid;

        if (!currentUserId) {
            // Handle this scenario (e.g., prompt user to log in or handle gracefully)
            setLoading(false);
            return;
        }

        const userStoriesQuery = query(
            collection(db, STORY_COLLECTION),
            where('author', '==', currentUserId)
        );

        const unsubscribe = onSnapshot(userStoriesQuery, snapshot => {
            const storiesList = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setStories(storiesList);
            setLoading(false);
        }, error => {
            console.error("Error listening to Firestore changes:", error);
            // Handle the error appropriately for your app
        });

        // Cleanup the listener when the component is unmounted
        return () => unsubscribe();
    }, [auth.currentUser]);

    if (loading) {
        return <p>Loading...</p>;
    }

//    if (stories.length === 0) {
//        return <p>No stories found</p>;
//    }

    return (
        <div>
            <h2>Stories</h2>
                {stories.map(story => (
                  <div style={{marginBottom: "8px"}}>
                    <StoryItem title={story.title}
                               status={story.status}
                               storyId={story.id}
                               setCurrentStory={props.setCurrentStory}
                               openDelete={openDelete}
                               setOpenDelete={setOpenDelete}
                    />
                  </div>
                ))}
            <div>
                {showNewStory && <>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex", alignItems: "center", gap: "10px", marginRight: "15px"}}>
                            <Fab color="primary" size="small" aria-label="add" onClick={() => {
                                makeAuthenticatedRequestToEndpoint("story", "POST", {title: newStoryTitle}, auth);
                                setShowNewStory(false);
                                setNewStoryTitle("");
                                }}>
                                <DoneOutlineRoundedIcon/>
                            </Fab>
                            <Fab color="primary" size="small" onClick={() => {
                                setShowNewStory(false);
                                setNewStoryTitle("")
                            }}>
                                <RemoveRoundedIcon/>
                            </Fab>
                        </div>
                        <div>
                            <TextField
                                id="standard-basic"
                                label="New story title"
                                variant="standard"
                                value={newStoryTitle}
                                style={{backgroundColor: 'white'}}
                                onChange={(event) => {
                                    setNewStoryTitle(event.target.value);
                                }}
                            />
                        </div>
                    </div>
                </>}
            </div>
            <div style={{marginTop: "20px"}}>
                <Fab color="primary" aria-label="add" onClick={() => setShowNewStory(true)}>
                    <AddIcon/>
                </Fab>
            </div>
        </div>
    );
};

export default StoryList;
