// import logo from './logo.svg';
import './App.css';
import { Auth } from './Auth';
import React from 'react';
import AuthorPage from './AuthorPage';
import PlayerPage from './PlayerPage';
import MainMenu from './MainMenu';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import StoryViewer from './StoryViewer';
import NotFoundPage from './NotFoundPage';
import AboutMePage from './AboutMePage';

function App() {
    return (
      <Router>
        <div class="container" style={{backgroundColor: "#335c67"}}>
          <div className="App">
            <header className="App-header">
            <Routes>
              <Route path="/" element={<AboutMePage />} />
              <Route path="/textAdventures" element={
              <>
                <div style={{backgroundColor: "#9E2A2B", padding: "5px", marginBottom: "10px", width: "100%", display: "flex", height: "100%"}}>
                  <Auth/>
                </div>
                <Outlet />
              </>}>
                <Route index element={<MainMenu />} />
                <Route path="story" element={<PlayerPage />} />
                <Route path="edit" element={<AuthorPage />} />
                <Route path="story/:lookupKey" element={<StoryViewer />} />
                <Route path="draft/:lookupKey" element={<StoryViewer isDraft={true} />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            </header>
          </div>
        </div>
      </Router>
  );
}

export default App;
