import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

function AboutMePage() {
    const navigate = useNavigate();

    return (
        <div>
            <h1>Hi,</h1>
            <p>This page is under construction. If you are here for text adventures, click here:</p>
            <Button variant="contained" onClick={()=>navigate(`/textAdventures`)}
                endIcon={<ArrowForwardIosIcon />} >
            </Button>
        </div>
    );
}

export default AboutMePage;