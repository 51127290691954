import React from 'react';
    
function NotFoundPage() {
  return (
    <div>
      {/* Add your code here */}
    </div>
  );
}

export default NotFoundPage;
