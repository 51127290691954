import { useEffect, useReducer } from 'react'; // Import useEffect and useState
import { getFirestore, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import useApi from './useApi';
import RequestStatusIndicator from './RequestStatusIndicator';
import ChoiceEditor from "./ChoiceEditor"; // Import necessary Firestore functions

const initialState = { text: "", choices: [] };

const SET_AFTER_LOAD = "setAfterLoad";
const EDIT_CHOICE = "editChoice";
const REMOVE_CHOICE = "removeChoice";
const SET_TEXT = "setText";
const CREATE_NEW_CHOICE = "createNewChoice";
const DRAFT_PASSAGES_COLLECTION = "draftPassages";

function editChoice(text, next, index, dispatch) {
    dispatch({type: EDIT_CHOICE, payload: {text: text, next: next, index: index}})
}

function removeChoice(index, dispatch) {
    dispatch({type: REMOVE_CHOICE, payload: {index: index}})
}

function reducer(state, action) {
    let newState;
    let newChoices;

    switch (action.type) {
        case SET_AFTER_LOAD:
            return action.payload;
        case EDIT_CHOICE:
            console.log(JSON.stringify(action));
            newChoices = [...state.choices];
            const index = action.payload.index;
            newChoices[index] = { ...newChoices[index], text: action.payload.text, next: action.payload.next };
            newState = {...state, choices: newChoices}
            console.log(JSON.stringify(newState));
            return newState;
        case REMOVE_CHOICE:
            newChoices = [...state.choices];
            newChoices.splice(action.payload.index, 1);
            newState = {...state, choices: newChoices}
            return newState;
        case SET_TEXT:
            console.log(state);
            return {...state, text: action.payload.text}
        case CREATE_NEW_CHOICE:
            return {...state, choices: [...state.choices, {text: "", next: 1}]};
        default:
            throw new Error();

    }}

async function savePassage(passageId, storyId, choices, text, db, callUpdateDoc) {
    console.log(text);
    const passageRef = doc(db, 'stories', storyId.toString(), DRAFT_PASSAGES_COLLECTION, passageId.toString());
    await callUpdateDoc(passageRef, {
        text: text,
        choices: choices
    });
}

async function deletePassage(passageId, storyId, db) {
    const passageRef = doc(db, 'stories', storyId.toString(), DRAFT_PASSAGES_COLLECTION, passageId.toString());
    await deleteDoc(passageRef);
}

function PassageEditor(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isSuccess, isLoading, error, call: callUpdateDoc } = useApi(updateDoc);
    const db = getFirestore();

    useEffect(() => {
        const fetchPassage = async () => {
            try {
                const passageRef = doc(db, 'stories', props.currentStory, DRAFT_PASSAGES_COLLECTION, props.currentPassageId.toString());
                const docSnapshot = await getDoc(passageRef);

                // Check if the document exists before using it
                if (docSnapshot.exists()) {
                    console.log(docSnapshot.data())
                    dispatch({ type: SET_AFTER_LOAD, payload: docSnapshot.data() })
                } else {
                    console.log('Document does not exist.');
                }
            } catch (error) {
                console.error('Error fetching document:', error);
            }
        };

        fetchPassage();
    }, [props.currentStory, props.currentPassageId, db]);

    return (
      <>
        <div dir={props.isDirectionRTL ? "rtl" : "ltr"}
             style={{display: "flex", flexDirection: "column", marginInlineStart: "5%", width: "55%",
            marginInlineEnd: "20%"}}>
            <TextField
                id="outlined-multiline-flexible"
                multiline
                maxRows={4}
                onChange={(event) => dispatch({ type: SET_TEXT, payload: {text: event.target.value} })}
                value={state.text}
                style={{marginBottom: "10px", backgroundColor: "wheat"}}
            />
            <div style={{marginTop: "10px"}}>
            {state.choices && (
                state.choices.map(choice => (
                        <ChoiceEditor text={choice.text}
                                      next={choice.next}
                                      index={state.choices.indexOf(choice)}
                                      passageIndexes={props.passageIndexes}
                                      dispatch={dispatch}
                                      editChoice={editChoice}
                                      removeChoice={removeChoice}
                                      isDirectionRTL={props.isDirectionRTL}
                        />
                    ))
            )}
            </div>
            <Fab color="primary" aria-label="add" onClick={() => dispatch({type: CREATE_NEW_CHOICE})}>
                <AddIcon/>
            </Fab>
          <div style={{display: "flex", justifyContent: "center"}}>
            <RequestStatusIndicator isSuccess={isSuccess} isLoading={isLoading} error={error} />
            <button onClick={() => savePassage(props.currentPassageId,
              props.currentStory, state.choices, state.text, db, callUpdateDoc)}
              style={{marginInlineEnd: "15px"}}>
                Save
            </button>
              {props.currentPassageId !== 1 &&
                <button onClick={() => {deletePassage(props.currentPassageId,
                  props.currentStory, db).then(r => props.setCurrentPassageId(1))}}>
                    Delete passage
                </button>}
          </div>
        </div>
      </>
    );
}

export default PassageEditor;
