import { useState } from 'react';

const useStack = (initialValue = []) => {
  const [stack, setStack] = useState(initialValue);

  const push = (element) => {
    setStack((prevStack) => [...prevStack, element]);
  };

  const pop = () => {
    if (!stack.length) {
      return undefined;
    }
    const lastElement = stack[stack.length - 1];
    setStack((prevStack) => prevStack.slice(0, -1));
    return lastElement;
  };

  const isEmpty = stack.length === 0;

  return { push, pop, isEmpty, stack };
};

export default useStack;