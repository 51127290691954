import { createSlice } from '@reduxjs/toolkit';

export const MODES = {
    MAIN_MENU: 'MainMenu',
    PLAYER: 'player',
    AUTHOR: 'author'
};

export const modeSlice = createSlice({
    name: 'mode',
    initialState: {
        mode: MODES.MAIN_MENU
    },
    reducers: {
        changeMode: (state, action) => {
            state.mode = action.payload;
        },
    },
});

// Export actions
export const { changeMode } = modeSlice.actions;

// Export reducer
export default modeSlice.reducer;