import React from 'react';
import Button from '@mui/material/Button';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';

function findFirstMissingInteger(passageIndexes) {
  for (let i = 0; i < passageIndexes.length; i++) {
      if (parseInt(passageIndexes[i]) !== i + 1) {
          return i + 1;
      }
  }
  return passageIndexes.length + 1;
}

async function createPassage(db, storyId, passageIndexes) {
  const newPassageIndex = findFirstMissingInteger(passageIndexes);
  console.log(newPassageIndex);
  const documentRef = doc(db, "stories", storyId, "draftPassages", newPassageIndex.toString());
  const docSnapshot = await getDoc(documentRef);

  if (!docSnapshot.exists()) {
    await setDoc(documentRef, {text: "", choices: []});
  }
}

function createDescriptions(passages, isDirectionRTL) {
  console.log("direction" + isDirectionRTL);
  // Create an object to hold arrays of choice texts for each passage ID
  const passageChoicesMap = {};

  // Populate the passageChoicesMap with empty arrays for each passage id
  passages.forEach(passage => {
    passageChoicesMap[passage.id] = [];
  });

  // Iterate over all passages and their choices
  passages.forEach(passage => {
    passage.choices.forEach(choice => {
      // Check if the choice.next is a passageId in the passageChoicesMap
      if (passageChoicesMap.hasOwnProperty(choice.next)) {
        const text = `${passage.id} -> ${choice.text}`;
        passageChoicesMap[choice.next].push(text);
      }
    });
  });

  const descriptions = passages.reduce((acc, passage) => {
    acc[passage.id] = passageChoicesMap[passage.id].slice(0, 3).join('\n');
    return acc;
  }, {});
  console.log(JSON.stringify(descriptions));
  return descriptions;
}

export function PassageMenu(props) {
  const db = getFirestore();
  const currentPassageIndex = props.currentPassageId;
  const passageMenuItems = [];
  const passsageDescriptions = props.passages ? createDescriptions(props.passages, props.isDirectionRTL) : null;
  const passageMenuData = props.passageIndexes.map(Number).sort((a, b) => a - b);
  for (let i = 0; i < passageMenuData.length; i++) {
      const storyIndex = passageMenuData[i];
      passageMenuItems.push(
        <Tooltip key={`tooltip-${i}`} 
          title={passsageDescriptions ? 
            <div style={{ whiteSpace: 'pre-line', textAlign: props.isDirectionRTL ? 'right' : 'left'}}>
              {passsageDescriptions[storyIndex]}
            </div> 
            : ""} 
          placement={props.isDirectionRTL ? "left" : 'right'}>
        <div>
          <Button variant={storyIndex === currentPassageIndex ? "contained" : "outlined"}
            key={"passage-menu-item-" + i}
            style={storyIndex === currentPassageIndex ? {} : {backgroundColor: "white", color: "black"}}
            sx={{marginBottom: '3px', width: '50%', border: 1, minWidth: '10px',
                height: '10%', borderRadius: 2}}
             onClick={() => {props.setCurrentPassageId(storyIndex); props.setOnSettings(false);}}>
            {storyIndex}
          </Button>
        </div>
        </Tooltip>
      );
  }
  return (
  <div style={{ display: "flex", flexDirection: "column"}}>
    <div style={{justifyContent: 'center'}}>
      <SettingsIcon onClick={() => props.setOnSettings(!props.onSettings)}/>
    </div>
    <div>
        {passageMenuItems}
        <Button
                sx={{marginBottom: '3px', width: '50%', border: 1, minWidth: '10px',
                height: '10%', borderRadius: 2, color: "black"}}
                style={{backgroundColor: "#e08b0b", text: "black", borderColor: "black", fontSize: "large"}}
                onClick={() => createPassage(db, props.storyId, props.passageIndexes)}
        >
        +
        </Button>
    </div>
   </div>
  );
}
